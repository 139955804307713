import "@styles/app.sass"
import "simple-lightbox/dist/simpleLightbox.css"
import SimpleLightbox from "simple-lightbox"
import Swiper, {Autoplay, EffectFade, Navigation} from "swiper"
import Swup from "swup"
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'

let swup = undefined,
    navOpener = undefined

if (!document.body.classList.contains('admin-bar')) {
   swup = new Swup({
      plugins: [
         new SwupBodyClassPlugin(),
         new SwupHeadPlugin(),
         new SwupScrollPlugin({
            doScrollingRightAway: true,
            animateScroll: false
         })
      ],
      ignoreVisit: (url, { el } = {}) => el?.parentElement.classList.contains('wp-block-image')
          || !!el?.closest('[data-photo-gallery]') || !!el?.closest('#wpadminbar')
   })
}

const init = () => {
   (() => { // Hiding header
      const header = document.querySelector('[data-page-header]') as HTMLElement
      if (!header) return

      const closeMenu = () => {
         document.body.classList.remove('u-hiddenOverflow')
         header.classList.remove('is-opened')
      }

      navOpener = document.querySelector('[data-nav-opener]')
      navOpener.addEventListener("click", () => {
         document.body.classList.toggle('u-hiddenOverflow')
         navOpener.closest('[data-page-header]')?.classList.toggle('is-opened')
      })

      header.querySelector('nav')?.querySelectorAll('a[href]')
          .forEach(el => el.addEventListener('click', closeMenu))

      window.addEventListener("keydown", ev => { ev.key === 'Escape' && closeMenu() })
      document.querySelector("main")?.addEventListener("click", () => {
         header.classList.contains("is-opened") && closeMenu()
      })
   })();

   (() => {
      const slider = document.querySelector('[data-hero-slides]') as HTMLElement
      if (!slider) return

      new Swiper(slider, {
         slidesPerView: 1,
         allowTouchMove: false,
         modules: [ Navigation, EffectFade, Autoplay ],
         navigation: {
            nextEl: '[data-hero-next]',
            prevEl: '[data-hero-prev]',
         },
         effect: 'fade',
         fadeEffect: {
            crossFade: true
         },
         autoplay: slider.dataset.autoplay ? {
            delay: parseInt(slider.dataset.autoplay) * 1000
         } : false
      })
   })();

   (() => {
      if (!document.querySelector('[data-photo-gallery]'))
         return

      new SimpleLightbox({
         elements: '[data-photo-gallery] a.GalleryPhoto'
      })
   })();

   (() => {
      if (!document.querySelector('.wp-block-gallery'))
         return

      new SimpleLightbox({
         elements: '.wp-block-gallery figure > a'
      })
   })();

   (() => {
      if (!document.querySelector('.wp-block-image'))
         return

      new SimpleLightbox({
         elements: '*:not(.wp-block-gallery) > .wp-block-image a'
      })
   })();

   (() => {
      const progressBarEl = document.querySelector("[data-article-progress]") as HTMLElement
      if (!progressBarEl) return

      const checkArticleProgress = () => {
         const winScroll = document.body.scrollTop || document.documentElement.scrollTop,
            height = document.documentElement.scrollHeight - document.documentElement.clientHeight

         progressBarEl.style.setProperty("--articleProgress", `${(winScroll / height) * 100}%`);
      }

      window.addEventListener("scroll", checkArticleProgress)
      checkArticleProgress()
   })();

   window[window.mapLoaded ? 'initMap' : 'loadMap']()
}

if (document.readyState === 'complete') {
   init()
} else {
   document.addEventListener('DOMContentLoaded', () => init())
}

const parser = new DOMParser()
swup?.on('contentReplaced', () => {
   const { originalContent } = swup?.cache.getCurrentPage();
   const page = parser.parseFromString(originalContent, 'text/html');
   const header = page.documentElement.querySelector('[data-page-header]');
   const lang = page.documentElement.getAttribute('lang');

   document.querySelector('[data-page-header]').innerHTML = header.innerHTML
   document.documentElement.setAttribute('lang', lang);

   /**
    * Plugins reInit
    */
   const forms = document.querySelectorAll( '.wpcf7 > form' );
   typeof wpcf7 === 'function' && forms.forEach(form => wpcf7.init( form ));

   typeof conditionally_show_banner === 'function' && conditionally_show_banner()

   /**
    * ReInit custom scripts
    */
   init()
})

swup?.on('pageView', () => {
   document.body.classList.remove('u-hiddenOverflow')
   navOpener.closest('[data-page-header]')?.classList.remove('is-opened')
})